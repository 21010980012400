import React from 'react'

import css from './App.module.css'
import { YandexMap } from './ui/components/Map'

function App() {
  return (
    <div className={`container`}>
      <h1 className={css.mainTitle}>Официальные дилеры Jeep</h1>
      <h3 className={css.title}>Найти дилерский центр</h3>
      <YandexMap />
    </div>
  )
}

export default App
